import React from 'react';
import { createUniqueMeeting, viewMeeting, joinMeeting } from "./meeting";

const FirebaseSandbox = () => {
    const createNewMeeting = () => {
        let meetingUrl = prompt("Enter a Zoom meeting link"); //"https://us04web.zoom.us/j/0000000000?pwd=dFlleVozd1lTK2Rsbm43U0hsRzlJUT09"
        let meetingTitle = "This was generated via the webapp";
        let meetingLecturer = "Barack Obama";
        let meetingStartsAt = "date"; //convert to firebase time;
        let isRestricted = false;
        let allowedEmailsArr = [];
        createUniqueMeeting(
            meetingUrl,
            meetingTitle,
            meetingLecturer,
            meetingStartsAt,
            isRestricted,
            allowedEmailsArr).then(meeting => {
                if (meeting.isUnique) {
                    console.log(`Meeting created successfully with the id of ${meeting.linkId}`);
                }
                else {
                    console.log("This meeting already exists: ", meeting.sameMeetingsByLinkId);
                }
            }).catch(error => {
                console.error(error.code, error.message);
                alert(error.message);
            });
    }

    const getMeeting = () => {
        const linkId = prompt("Enter meeting link ID");
        viewMeeting(linkId).then(meeting => {
            console.log(meeting);
        }).catch(error => {
            console.error(error.code, error.message);
            alert(error.message);
        });
    }

    const launchMeeting = () => {
        const linkId = prompt("Enter meeting link ID");
        joinMeeting(linkId).then(meeting => {
            console.log(meeting);
        }).catch(error => {
            console.error(error.code, error.message);
            alert(error.message);
        });
    }

    return (
        <div>
            <button onClick={createNewMeeting}>Create new meeting</button>
            <button onClick={getMeeting}>get meeting</button>
            <button onClick={launchMeeting}>join meeting</button>
        </div>
    )
}

export default FirebaseSandbox
