import React, { useState } from 'react';
//import PropTypes from 'prop-types'
import { HashLink } from 'react-router-hash-link';
import { Header, Segment, Input, Button, Icon, Message } from 'semantic-ui-react';
import sanitizeAndValidate from "../../Firebase/sanitizeAndValidate";

const CredsPromptInput = props => {
    const { onCredChange, onConfirm, isWorking } = props;
    const [isValid, setIsValid] = useState(false);

    const setInput = input => {
        setIsValid(sanitizeAndValidate.validateEmail(input));
        onCredChange(input);
    }

    return (
        <>
            <Header as="h2">
                <Header.Content>
                    אמת את זהותך כדי להמשיך
                        <Header.Subheader>כך נוכל כולנו להנות ממפגש ללא הפרעות מיותרות</Header.Subheader>
                </Header.Content>
            </Header>
            <Input fluid type='email' placeholder='example@email.com' iconPosition='left' onChange={(e, data) => setInput(data.value)} action>
                <input />
                <Icon name="mail" />
                <Button onClick={onConfirm} loading={isWorking} disabled={isWorking || !isValid} >
                    אימות
                        <Icon name='chevron right' />
                </Button>
            </Input>
            <p>לנוחיותך, אנו ננסה לזכור את כתובת המייל שלך בכדי שלא יהיה צורך להזין אותה שוב במפגשים הבאים</p>
        </>
    )
};


const EmailSent = () => {
    return (
        <Header as="h2" icon>
            <Icon name='mail' inverted circular color="green" />
            <Header.Content>
                ההודעה נשלחה!
                <Header.Subheader><strong>בדוק את תיבת הדואר הנכנס שלך.</strong> שלחנו לך מייל לאימות. אם לא קיבלת את המייל יש לבדוק בתיבת דואר הזבל (spam). <HashLink smooth to={`../${process.env.REACT_APP_PATH_HELP}#email-verification`}>לעזרה לחצ/י כאן.</HashLink></Header.Subheader>
            </Header.Content>
        </Header>
    );
}


const CredentialsPrompt = props => {
    const { isWorking, isSent, onSubmit: submit, onEmailChange } = props;

    const emailChange = value => {
        onEmailChange(value);
    }

    return (
        <>
            <Segment textAlign="center" attached='top' color={isSent ? 'green' : 'orange'}>
                {isSent ? <EmailSent /> : <CredsPromptInput onConfirm={submit} onCredChange={emailChange} isWorking={isWorking} />}
            </Segment>
            <Message attached='bottom' positive>
                <Icon name='help' />
                <strong>לאחר שליחת כתובת המייל שלך, תשלח אליך הודעת אימות. &nbsp;</strong>
                <HashLink smooth to={`../${process.env.REACT_APP_PATH_HELP}#email-verification`}><u>עזרה</u></HashLink>
            </Message>
        </>
    )
}

/*CredentialsPrompt.propTypes = {

}*/

export default CredentialsPrompt;