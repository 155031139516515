import React from 'react'
import { Redirect, Route } from "react-router-dom";
import { Loader } from 'semantic-ui-react';
import { useAuth } from '../../contexts/Firebase/AuthContext';
import ErrorMessage from '../Error/ErrorMessage';

const PrivateRoute = ({ component: Component, adminOnly, ...rest }) => {
    const { currentUser, adminData } = useAuth();
    return (
        <Route {...rest} render={props => {
            const meetingId = props.match.params.meetingId;
            const afterLogQuery = meetingId ? `?redirect=${process.env.REACT_APP_PATH_ZOOM_MEETING.replace('/', '')}&item=${meetingId}` : '';
            if (!!currentUser) {
                if (adminOnly) {
                    if (adminData.loading) return <Loader active inline="centered" content="טוען" />;
                    else if (adminData.error) return <ErrorMessage message={adminData.error.message} />;
                    else if (adminData.data && adminData.data.isAdminEnabled) return <Component {...props} />;
                    else return <Redirect to={process.env.REACT_APP_PATH_HOME} />
                }
                else return <Component {...props} />;
            }
            else return <Redirect to={`${process.env.REACT_APP_PATH_LOGIN}${afterLogQuery}`} />;
            //return currentUser ? <Component {...props} /> : <Redirect to={`${process.env.REACT_APP_PATH_LOGIN}${afterLogQuery}`} />
        }}>
        </Route>
    )
}

export default PrivateRoute
