const supportedLanguages = ['en', 'he'];
let defaultLanguage = '';

const createMeetingErrors = {
    invalidURL: {
        code: 'create-meeting/invalidURL',
        message: () => {
            const messages = {
                default: "Meeting URL is invalid. Make sure you've copied the entire zoom link (a valid link scheme: 'https://[region (not required)].zoom.us/[action]/[10 digit meeting ID]?pwd=[32 byte password hash]'. An example valid link is: 'https://us04web.zoom.us/j/1234567890?pwd=32BYtEhASheNCoDEdpASsWoRd1234567').",
                'en': "Meeting URL is invalid. Make sure you've copied the entire zoom link (a valid link scheme: 'https://[region (not required)].zoom.us/[action]/[10 digit meeting ID]?pwd=[32 byte password hash]'. An example valid link is: 'https://us04web.zoom.us/j/1234567890?pwd=32BYtEhASheNCoDEdpASsWoRd1234567').",
                'he': "הקישור למפגש אינו תקין. וודא שהעתקת את כל חלקי קישור ה'זום' (תבנית של קישור תקין נראית ככה: 'https://[region (not required)].zoom.us/[action]/[10 digit meeting ID]?pwd=[32 byte password hash]'. דוגמה לקישור תקין נראית ככה: 'https://us04web.zoom.us/j/1234567890?pwd=32BYtEhASheNCoDEdpASsWoRd1234567').",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }

    },
    noTitle: {
        code: 'create-meeting/noTitle',
        message: () => {
            const messages = {
                default: "Meeting title is required but missing.",
                'en': "Meeting title is required but missing.",
                'he': "לפגישה נדרשת כותרת.",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }
    },
    noIsRestricted: {
        code: 'create-meeting/noIsRestricted',
        message: () => {
            const messages = {
                default: "Meeting's restriction setting (public/restricted) is not set.",
                'en': "Meeting's restriction setting (public/restricted) is not set.",
                'he': "הגדרת השיתוף של הפגישה (מוגבל/ציבורי) אינה מוגדרת.",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }

    },
    isRestrictedNoAtendees: {
        code: 'create-meeting/isRestrictedNoAtendees',
        message: () => {
            const messages = {
                default: "The meeting is restricted but no allowed atendees were given.",
                'en': "The meeting is restricted but no allowed atendees were given.",
                'he': "הגישה לפגישה הוגדרה כמוגבלת למוזמנים בלבד אך לא סופקה רשימת מוזמנים.",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }
    },
}

const fetchMeetingErrors = {
    notFound: {
        code: 'view-meeting/notFound',
        message: () => {
            const messages = {
                default: "This meeting does not exist.",
                'en': "This meeting does not exist.",
                'he': "פגישה זו לא קיימת במערכת.",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }

    },
    noCredentials: {
        code: 'join-meeting/noMeetingCredentials',
        message: () => {
            const messages = {
                default: "Failed to join this meeting. Please contact the meeting host.",
                'en': "Failed to join this meeting. Please contact the meeting host.",
                'he': "הייתה בעיה בזמן נסיון ההצטרפות לפגישה. נא ליצור קשר עם מארגן המפגש.",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }

    },
}

const generic = {
    unknown: {
        code: 'igs-link/unknownError',
        message: () => {
            const messages = {
                default: "An error occurred.",
                'en': "An error occurred.",
                'he': "אירעה שגיאה.",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }

    }
}

const firebaseErrorsByCode = {
    'permission-denied': {
        code: 'view-meeting/noPermission',
        message: () => {
            const messages = {
                default: "This meeting is private. If you belive that you should be able to view this meeting, please contact this meeting's host.",
                'en': "This meeting is private. If you belive that you should be able to view this meeting, please contact this meeting's host.",
                'he': "מפגש זה הינו למוזמנים בלבד. אם אתה סבור כי חלה טעות, יש ליצור קשר עם מארגן המפגש.",
            }
            if (defaultLanguage) return messages[defaultLanguage];
            return messages.default;
        }

    }
}

const firebaseErrorCodes = {
    permissionDenied: 'permission-denied',
}

const setLang = lang => {
    if (supportedLanguages.includes(lang)) {
        defaultLanguage = lang;
    }
    else {
        console.warn(`[Error Index]: "${lang}" is not a supported language. \
        Showing errors in the previous set language or in english if none. \
        If you meant to reset the default language, please call the 'resetLanguage()' method (takes no arguments).`);
    }
}

const resetLang = () => {
    defaultLanguage = '';
}


const setLanguage = setLang;
const resetLanguage = resetLang;
const IgsErrorsIndex = { createMeetingErrors, fetchMeetingErrors, generic, firebaseErrorsByCode, setLanguage, resetLanguage, firebaseErrorCodes };
export default IgsErrorsIndex;