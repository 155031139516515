class IgsError extends Error {
    constructor(error, ...params) {
        super(...params)

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, IgsError)
        }

        this.name = error.code;
        // Custom debugging information
        this.message = error.message();
        this.code = error.code;
        this.date = new Date();
    }
}

export default IgsError;