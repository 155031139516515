import { db, Timestamp, util } from "./firebase";
import IgsErrorsIndex from "../../constants/errorIndex";
import IgsError from "../Error/error";
import { fetchSameMeetingsByZoomId } from "./search";

IgsErrorsIndex.setLanguage(process.env.REACT_APP_ERRORS_LANGUAGE);

const zoomMeetings = db.collection(process.env.REACT_APP_ZOOM_MEETINGS_REF);

const getMeetingRestrictedDataDocsRef = meetingDocId => {
    const restrictedCol = zoomMeetings.doc(meetingDocId).collection(process.env.REACT_APP_ZOOM_MEETINGS_RESTRICTED_COL_REF);
    return {
        access: restrictedCol.doc(process.env.REACT_APP_ZOOM_MEETINGS_ACCESS_DOC_REF),
        credentials: restrictedCol.doc(process.env.REACT_APP_ZOOM_MEETINGS_CREDENTIALS_DOC_REF)
    }
}

const fetchMeeting = meetingLinkId => {
    const errorsGroup = IgsErrorsIndex.fetchMeetingErrors;
    const firebaseErrorsGroup = IgsErrorsIndex.firebaseErrorsByCode;
    const fireCodes = IgsErrorsIndex.firebaseErrorCodes;
    if (!meetingLinkId) return Promise.reject(new IgsError(errorsGroup.notFound));
    return zoomMeetings.doc(meetingLinkId).get().then(meeting => {
        if (meeting.exists) {
            return Promise.resolve(meeting.data());
        }
        else return Promise.reject(new IgsError(errorsGroup.notFound));
    }).catch(error => {
        if (error.code === fireCodes.permissionDenied) return Promise.reject(new IgsError(firebaseErrorsGroup[fireCodes.permissionDenied]));
        return Promise.reject(error);
    });
}

const fetchMeetingCredentialsForJoin = meetingLinkId => {
    const errorsGroup = IgsErrorsIndex.fetchMeetingErrors;
    if (!meetingLinkId) return Promise.reject(new IgsError(errorsGroup.notFound));
    const restricted = zoomMeetings.doc(meetingLinkId).collection(process.env.REACT_APP_ZOOM_MEETINGS_RESTRICTED_COL_REF);
    const creds = restricted.doc(process.env.REACT_APP_ZOOM_MEETINGS_CREDENTIALS_DOC_REF);

    return creds.get().then(credentials => {
        if (credentials.exists) {
            return Promise.resolve(credentials.data())
        }
        else return Promise.reject(new IgsError(errorsGroup.noCredentials));
    }).catch(error => {
        return Promise.reject(error);
    });
}

const generateUniqueMeeting = meetingObj => {
    const { meetingUrl, meetingTitle, meetingLecturer, meetingStartsAt, isRestricted, allowedEmailsArr } = meetingObj;
    const errorsGroup = IgsErrorsIndex.createMeetingErrors;
    let url = null;
    try {
        url = new URL(meetingUrl);
    } catch (error) {
        return Promise.reject(new IgsError(errorsGroup.invalidURL));
    }
    const accessControl = {
        isRestricted: isRestricted,
        allowedEmails: allowedEmailsArr
    }
    const credentials = {
        meetingZoomId: url.pathname.split('/')[2],
        passwordHash: url.searchParams.get("pwd")
    }
    let meeting = {
        title: meetingTitle,
        lecturer: meetingLecturer,
        startsAt: Timestamp.fromDate(meetingStartsAt),
        access: {
            isRestricted: accessControl.isRestricted,
        },
        metadata: {
            createdAt: Timestamp.now(),
            createdByUuid: util.auth().currentUser.uid
        }
    }

    if (!credentials.meetingZoomId || !credentials.passwordHash) return Promise.reject(new IgsError(errorsGroup.invalidURL));
    if (!meeting.title) return Promise.reject(new IgsError(errorsGroup.noTitle));
    //TODO: Check time format is valid
    if (typeof accessControl.isRestricted !== "boolean") return Promise.reject(new IgsError(errorsGroup.noIsRestricted));
    else if ((!Array.isArray(accessControl.allowedEmails) || !accessControl.allowedEmails.length) && accessControl.isRestricted !== false) return Promise.reject(new IgsError(errorsGroup.isRestrictedNoAtendees));

    //TODO (in later stages): Instead of checking if there is a same meeting id - 
    //index all the meetings and update index via transaction before(!) creating a meeting
    return fetchSameMeetingsByZoomId(credentials.meetingZoomId).then(sameMeetings => {
        let response = {
            isUnique: !sameMeetings.exist,
            linkId: null,
            sameMeetingsByLinkId: sameMeetings.meetingsByLinkId
        }
        if (!sameMeetings.exist) {
            return zoomMeetings.add(meeting).then(meetingRef => {
                response.linkId = meetingRef.id;
                return getMeetingRestrictedDataDocsRef(meetingRef.id).access.set(accessControl).then(() => {
                    return getMeetingRestrictedDataDocsRef(meetingRef.id).credentials.set(credentials).then(() => {
                        return Promise.resolve(response);
                    });
                });
            });
        }
        return Promise.resolve(response);
    }).catch(error => {
        return Promise.reject(error);
    });
}

/*const timeToTime = () => {
    //Constructs a Date object with this date (local timezone)
    //                       ----, 0-11,  --,1-24,--, --
    let inputTime = new Date(2021, 3 - 1, 20, 24, 18, 10);
    //Convert it to a firestore timestamp which also converts it to UTC
    let ts = Timestamp.fromDate(new Date(inputTime));
    //Convert the timestamp back to Date and to the local timezone (not necessarily the original, just local)
    let tsDate = ts.toDate();
    //Print the new Date from timestamp but formatted as a UTC converted string
    console.log(tsDate.toUTCString());
}*/

export const createUniqueMeeting = generateUniqueMeeting;
export const viewMeeting = fetchMeeting;
export const joinMeeting = fetchMeetingCredentialsForJoin;
const methods = { createUniqueMeeting, viewMeeting, joinMeeting };
export default methods;