import React, { Fragment } from 'react'
import { Segment, Icon, Header } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { helpArticles } from "./HelpContent";

const HelpArticleTitle = props => {
    const { title, hashLink } = props;
    const linkToArticle = window.location.origin + process.env.REACT_APP_PATH_HELP + "#" + hashLink;
    return (
        <>
            <h1 id={hashLink}>
                <CopyToClipboard text={linkToArticle} style={{ cursor: "pointer" }}>
                    <span><Icon name="chain" /></span>
                </CopyToClipboard>
                &nbsp;{title}
            </h1>
            <hr />
        </>
    );
}

const HelpArticle = props => {
    const { content } = props;
    return (
        <p>{content}</p>
    );
}

const Help = () => {
    const articles = helpArticles.map((article, index) => {
        return (
            <Fragment key={index}>
                <HelpArticleTitle title={article.title} hashLink={article.hashLink} />
                <HelpArticle content={article.content} />
                <br />
            </Fragment>
        );
    });
    return (
        <>
            <center>
                <Header as='h1' color="green" style={{ fontSize: "40px" }}>
                    מרכז התמיכה
                    <Header.Subheader>
                        צריך עזרה? הנה כמה תשובות למספר שאלות נפוצות
                    </Header.Subheader>
                </Header>
            </center>
            <Segment dir="rtl" color="green">
                {articles}
            </Segment>
        </>
    );
}

export default Help
