import { db, util } from "./firebase";

const zoomMeetings = db.collection(process.env.REACT_APP_ZOOM_MEETINGS_REF);

const _fetchRecentMeetings = () => {
    const meetingsQuery = zoomMeetings.orderBy("metadata.createdAt", "desc").limit(10);
    return meetingsQuery.get().then(querySnapshot => {
        let searchResults = [];
        if (!querySnapshot.empty) {
            searchResults = querySnapshot.docs.map(meeting => {
                return { id: meeting.id, data: meeting.data() };
            });
        }
        return Promise.resolve({ exist: !querySnapshot.empty, meetings: searchResults });
    }).catch(error => {
        return Promise.reject(error);
    });
}

const _fetchMeetingDetailsByZoomId = meetingZoomId => {
    return _fetchSameMeetingsByZoomId(meetingZoomId).then(result => {
        let returnResult = { exist: result.exist, meetings: [] };
        if (result.exist) {
            //Note that the max linkIds we can search is 10!!!! But this should'nt happen in production
            const meetingsQuery = zoomMeetings.where(util.firestore.FieldPath.documentId(), 'in', result.meetingsByLinkId);
            return meetingsQuery.get().then(querySnapshot => {
                let searchResults = [];
                if (querySnapshot.empty) returnResult.exist = false;
                else {
                    searchResults = querySnapshot.docs.map(meeting => {
                        return { id: meeting.id, data: meeting.data() };
                    });
                }
                returnResult.meetings = searchResults;
                return Promise.resolve(returnResult);
            }).catch(error => {
                return Promise.reject(error);
            });
        }
        return Promise.resolve(returnResult);
    }).catch(error => {
        return Promise.reject(error);
    });
}

const _fetchSameMeetingsByZoomId = meetingZoomId => {
    const sameMeetings = db.collectionGroup(process.env.REACT_APP_ZOOM_MEETINGS_RESTRICTED_COL_REF).where("meetingZoomId", "==", meetingZoomId);
    return sameMeetings.get().then(meetingsQuerySnapshot => {
        let sameMeetingLinkIDs = [];
        if (!meetingsQuerySnapshot.empty) {
            sameMeetingLinkIDs = meetingsQuerySnapshot.docs.map(meeting => {
                //meeting.ref.parent is "credentials" and credentials.parent is the "same-meeting" id
                return meeting.ref.parent.parent.id;
            });
        }
        return Promise.resolve({ exist: !meetingsQuerySnapshot.empty, meetingsByLinkId: sameMeetingLinkIDs });
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const fetchSameMeetingsByZoomId = _fetchSameMeetingsByZoomId;
export const fetchRecentMeetings = _fetchRecentMeetings;
export const fetchMeetingDetailsByZoomId = _fetchMeetingDetailsByZoomId;
const methods = { fetchSameMeetingsByZoomId, fetchRecentMeetings, fetchMeetingDetailsByZoomId };
export default methods;