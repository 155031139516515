import React, { useState } from 'react'
import CredentialsPrompt from './CredentialsPrompt/CredentialsPrompt';
import { useLocation } from "react-router-dom";

import { useAuth } from "../../contexts/Firebase/AuthContext";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Login = props => {
    const [isSent, setIsSent] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const { sendSignInEmail, currentUser } = useAuth();
    const query = useQuery();

    const redirect = {
        destination: query.get("redirect"),
        item: query.get("item")
    };

    const afterLoginRedirectString = (redirect.destination && redirect.item) ? `?redirect=${redirect.destination}&item=${redirect.item}` : "";
    const ifLoggedRedirectString = (currentUser && afterLoginRedirectString) ? `/${redirect.destination}/${redirect.item}` : "/"

    let emailString = "";
    const setEmail = value => {
        emailString = value;
    }

    const sendAuthLink = () => {
        //Check if email is valid format first and if so return the function that handles it
        //If email is ok, indicate loading and disable UI
        setIsWorking(true);
        sendSignInEmail(emailString, afterLoginRedirectString).then(() => {
            window.localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_EMAIL_FOR_SIGNIN, emailString);
            //Indicate that an email was sent
            setIsSent(true);
        }).catch(error => {
            setIsWorking(false);
            setIsSent(false);
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
        });
    }

    return !currentUser ? <CredentialsPrompt isWorking={isWorking} isSent={isSent} onSubmit={sendAuthLink} onEmailChange={setEmail} /> : <>{props.history.replace(ifLoggedRedirectString)}</>;
}

export default Login
