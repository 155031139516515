import React, { Fragment, useState } from 'react';
import { Form, Header, Icon, Segment } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import sanitizeAndValidate from "../Firebase/sanitizeAndValidate";
import { createUniqueMeeting } from '../Firebase/meeting';
import ErrorMessage from '../Error/ErrorMessage';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CreateMeeting = props => {
    const { show } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [result, setResult] = useState({ done: false, meetingShareLink: null });

    const createMeeting = meetingData => {
        setIsLoading(true);
        setError();
        createUniqueMeeting(meetingData).then(response => {
            setIsLoading(false);
            if (!response.sameMeetingsByLinkId.length) {
                setError();
                const shareLink = window.location.origin + process.env.REACT_APP_PATH_ZOOM_MEETING + '/' + response.linkId;
                setResult({ done: true, meetingShareLink: shareLink });
            }
            else {
                setError({ message: 'מפגש זה כבר קיים' });
            }
        }).catch(error => {
            setError(error);
            setIsLoading(false);
        })
    }

    return (
        <>
            {
                show &&
                <Segment>
                    <Header as="h2" dividing>צור מפגש</Header>
                    {error && <ErrorMessage message={error.message} />}
                    {!result.done && <NewMeetingForm isLoading={isLoading} onCreateMeeting={createMeeting} />}
                    {result.done &&
                        <Header as="h2" textAlign='center' icon>
                            <Icon name="check circle outline" color="green" />
                            המפגש נוצר בהצלחה!
                            <Header.Subheader>
                                <h4>
                                    הקישור שניתן לשתף הוא: <br />
                                    <CopyToClipboard text={result.meetingShareLink} style={{ cursor: "pointer" }}>
                                        <u>{result.meetingShareLink} (לחץ כדי להעתיק)</u>
                                    </CopyToClipboard>
                                </h4>
                            </Header.Subheader>
                        </Header>
                    }
                </Segment>
            }
        </>
    )
}


const NewMeetingForm = props => {
    const { isLoading, onCreateMeeting } = props;
    const [title, setTitle] = useState();
    const [lecturer, setLecturer] = useState();
    const [link, setLink] = useState();
    const [startsAt, setStartsAt] = useState();
    const [isRestricted, setIsRestricted] = useState();
    const [invitedAtendees, setInvitedAtendees] = useState([]);
    const [inputErrors, setInputError] = useState({
        title: '',
        lecturer: '',
        link: '',
        startsAt: '',
        isRestricted: '',
        invitedAtendees: ''
    });

    const setAccessSetting = input => {
        setIsRestricted(input === 'restricted');
    }

    const compileMeeting = () => {
        let error = {
            title: '',
            lecturer: '',
            link: '',
            startsAt: '',
            isRestricted: '',
            invitedAtendees: ''
        };

        const missingFieldMessage = 'שדה חובה';
        if (!link) error.link = missingFieldMessage;
        if (!title) error.title = missingFieldMessage;
        if (!lecturer) error.lecturer = missingFieldMessage;
        if (!startsAt) error.startsAt = missingFieldMessage;
        if (typeof isRestricted !== "boolean") error.isRestricted = missingFieldMessage;
        if (!invitedAtendees && isRestricted) error.link = missingFieldMessage;

        setInputError(error);

        let continueFlag = !error.link && !error.title && !error.lecturer && !error.startsAt && !error.isRestricted && !error.invitedAtendees;

        let meeting = {
            meetingUrl: link,
            meetingTitle: title,
            meetingLecturer: lecturer,
            meetingStartsAt: startsAt,
            isRestricted: isRestricted,
            allowedEmailsArr: invitedAtendees
        }

        if (continueFlag) onCreateMeeting(meeting);
    }

    const sanitizeAtendees = (e, data) => {
        const rawInput = data.value;
        let atendees = [];
        let errorFlag = false;
        let error = inputErrors;
        let emailsArray = rawInput.replace(/\s/g, '').split(",");
        emailsArray.forEach(email => {
            if (!errorFlag) {
                if (sanitizeAndValidate.validateEmail(email)) {
                    errorFlag = false;
                    error.invitedAtendees = "";
                    setInputError(error);
                    atendees.push(email);
                }
                else {
                    errorFlag = true;
                    if (email) {
                        error.invitedAtendees = `"${email}" הוא מייל לא תקין`;
                        setInputError(error);
                    }
                    else if (!atendees.length) {
                        error.invitedAtendees = "שדה חובה";
                        setInputError(error);
                    }
                    //Else ignore
                }
            }
        });
        setInvitedAtendees(errorFlag ? [] : atendees);
    }


    const accessOptions = [
        { key: 'r', text: 'מוגבל', value: 'restricted', icon: 'lock', description: 'המפגש יהיה פתוח למוזמנים בלבד' },
        { key: 'p', text: 'ציבורי', value: 'public', icon: 'globe', description: 'המפגש יהיה פתוח לקהל הרחב' },
    ]

    return (
        <Form loading={isLoading}>
            <Form.Group widths='equal'>
                <Form.Input fluid label='כותרת/נושא' placeholder='לדוגמה: מי הם האבות המיידסים של ירוחם' error={!!inputErrors.title && inputErrors.title} onChange={(e, data) => setTitle(data.value)} />
                <Form.Input fluid label='מנחה/מרצה' placeholder="לדוגמה: פרופ' ישראל ישראלי" error={!!inputErrors.lecturer && inputErrors.lecturer} onChange={(e, data) => setLecturer(data.value)} />
            </Form.Group>
            <Form.Input fluid label='לינק הצטרפות מתוכנת ה-"זום"' error={!!inputErrors.link && inputErrors.link} placeholder='e.g: https://us04web.zoom.us/j/1234567890?pwd=32BYtEhASheNCoDEdpASsWoRd1234567' onChange={(e, data) => setLink(data.value)} />
            <Form.Group widths='equal'>
                <Form.Field error={!!inputErrors.startsAt && inputErrors.startsAt}><label>זמן תחילת המפגש</label><DateTimePicker onSelected={setStartsAt} /></Form.Field>
                <Form.Field><label>שים לב!</label><p>מנהלים יכולים להצטרף לכל פגישה</p></Form.Field>
            </Form.Group>
            <Form.Select
                fluid
                label="בקרת גישה"
                options={accessOptions}
                placeholder='Public/Restricted'
                error={!!inputErrors.isRestricted && inputErrors.isRestricted}
                onChange={(e, data) => setAccessSetting(data.value)} />
            {isRestricted &&
                <Form.TextArea
                    label={
                        <>
                            <strong>רשימת מוזמנים</strong>
                            <span style={{ color: 'grey' }}> | כתובות מייל המופרדות בפסיקים</span>
                        </>
                    }
                    error={!!inputErrors.invitedAtendees && inputErrors.invitedAtendees}
                    onChange={sanitizeAtendees}
                    placeholder='לדוגמה: example@example.com, name@email.net, atendee@mail.co.uk' />}
            <Form.Button color="green" onClick={compileMeeting}>צור מפגש</Form.Button>
        </Form>
    );
}

const DateTimePicker = props => {
    const { onSelected } = props;
    const [startDate, setStartDate] = useState(new Date());
    const setCalendar = date => {
        setStartDate(date);
        onSelected(date);
    }
    return (
        <DatePicker showTimeSelect dateFormat="dd/MM/yyyy, HH:mm" timeFormat="HH:mm" timeIntervals={15} selected={startDate} onChange={date => setCalendar(date)} />
    )
}

export default CreateMeeting
