import React, { useState } from 'react'
import { Redirect, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Header, Segment, Icon } from 'semantic-ui-react';

import { useAuth } from "../../../contexts/Firebase/AuthContext";
import ErrorMessage from '../../Error/ErrorMessage';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const AuthConfirm = props => {
    const { onConfirm } = props;
    return (
        <>
            <Header as="h2" icon>
                <Icon name='user circle' inverted color="orange" />
                <Header.Content>
                    ברוכים הבאים!
                    <Header.Subheader>כדי להמשך, עלינו לוודא תחילה שאת/ה לא רובוט (יש לסמן את התיבה למטה).</Header.Subheader>
                </Header.Content>
            </Header>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onConfirm}
            />
        </>
    );
}


const AuthLanding = props => {
    const [error, setError] = useState();
    const query = useQuery();

    const redirect = {
        destination: query.get("redirect"),
        item: query.get("item")
    };

    const redirectLink = `../${redirect.destination ? redirect.destination : ""}${redirect.destination && redirect.item ? "/" + redirect.item : ""}`;

    const { passwordlessSignIn, currentUser } = useAuth();
    const auth = reCaptchValue => {
        const link = window.location.href;
        if (reCaptchValue) {
            passwordlessSignIn(link).then(result => {
                //Alert for success
                window.localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_EMAIL_FOR_SIGNIN);
            }).catch(error => {
                //Alert of an error
                setError(error);
                console.error(error);
            });
        }
    }
    return (
        <>
            {error &&
                <ErrorMessage message={error.message} />
            }
            <Segment textAlign="center" color='orange'>
                {currentUser && !error ? <Redirect to={redirectLink} /> : <AuthConfirm onConfirm={auth} />}
            </Segment>
        </>
    )
}

export default AuthLanding;
