import dateFormat from 'dateformat';
import React, { useState } from 'react';
import { Button, Header, Label, Segment, Container, Message, Icon } from 'semantic-ui-react';

function ZoomMeetingDetails(props) {
    const [isWaiting, setWaiting] = useState(false);
    const { meeting, allowJoin, launchMeeting } = props;

    const handleZoomLaunch = () => {
        setWaiting(true);
        launchMeeting().then(() => {
            setWaiting(false);
        });
    }

    const { title, lecturer, startsAt, access } = meeting.data;

    const dateFormatString = "dd/mm/yyyy בשעה HH:MM";
    const statusColor = startsAt.toDate() > new Date() ? "green" : "orange";

    return (
        <Container>
            <Segment textAlign="center" attached="top" color={statusColor} >
                <Header as='h2'>
                    <Label color={statusColor} basic><h5>{dateFormat(startsAt.toDate(), dateFormatString)}</h5></Label>
                    <br />{title}
                    <Header.Subheader>
                        מנחים/מרצים: <strong>{lecturer}</strong>
                    </Header.Subheader>
                </Header>
                {allowJoin && <Button onClick={handleZoomLaunch} color="green" loading={isWaiting}>הצטרף!</Button>}
            </Segment>
            <Message attached='bottom'>
                <Icon name={access.isRestricted ? 'lock' : 'globe'} />
                {access.isRestricted ? 'מפגש זה פתוח למוזמנים בלבד' : 'מפגש זה פתוח לקהל הרחב'}
            </Message>
        </Container>
    )
}

export default ZoomMeetingDetails;