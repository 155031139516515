import React, { Component } from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import "./App.css";

import NavBar from "../NavBar/Navbar";
import ZoomMeeting from "../Zoom/Meeting";
import About from "../About/About";
import { AuthProvider } from "../../contexts/Firebase/AuthContext";
import AuthLanding from "../Login/CredentialsPrompt/AuthLanding";
import Error404 from "../ErrorPage/Error404";
import Login from "../Login/Login";
import Help from "../Help/Help";
import FirebaseSandbox from "../Firebase/Sandbox";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../Dashboard/Dashboard";
class App extends Component {
  render() {
    return (
      <Router>
        <AuthProvider>
          <NavBar />
          <div className="app-container">
            <Switch>
              <Route exact path='/'><Redirect to={process.env.REACT_APP_PATH_ABOUT} /></Route>
              <Route path={process.env.REACT_APP_PATH_ISRAGEN_REDIRECTOR} component={() => {
                window.location.replace('https://www.isragen.org.il');
                return null;
              }} />
              <Route path={process.env.REACT_APP_PATH_ABOUT} component={About} />
              <Route path={process.env.REACT_APP_PATH_REPORT}><h1>REPORT</h1></Route>
              <PrivateRoute exact path='/meeting/:meetingId' component={ZoomMeeting} />
              <Route exact path={process.env.REACT_APP_EMAIL_AUTH_LANDING} component={AuthLanding} />
              <Route exact path={process.env.REACT_APP_PATH_LOGIN} component={Login} />
              <Route path={process.env.REACT_APP_PATH_HELP} component={Help} />
              <PrivateRoute adminOnly exact path={process.env.REACT_APP_PATH_DASHBOARD} component={Dashboard} />
              {/* --------------------TEMPORARY-------------------- */}
              <Route path="/sandbox" component={FirebaseSandbox} />
              {/* ------------------------------------------------- */}
              <Route path="*" component={Error404} />
            </Switch>
          </div>
        </AuthProvider>
      </Router>
    );
  }
}

export default App;