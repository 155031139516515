import React, { useState, useEffect } from 'react';
import { Segment, Input, Icon, Header, Button, Placeholder, Table, Message, Label } from 'semantic-ui-react';
import ErrorMessage from "../Error/ErrorMessage";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fetchRecentMeetings, fetchMeetingDetailsByZoomId } from '../Firebase/search';
import dateFormat from 'dateformat';

const recentQueryTerm = 'recent';

const SearchAndEdit = props => {
    const { show } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState();

    const searchMeeting = searchQuery => {
        setIsLoading(true);
        setError();
        if (searchQuery === recentQueryTerm) {
            fetchRecentMeetings().then(recentSearchResults => {
                setSearchResults({ query: searchQuery, results: recentSearchResults });
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                setError(error);
            });
        }
        else {
            fetchMeetingDetailsByZoomId(searchQuery).then(meetingSearchResults => {
                setSearchResults({ query: searchQuery, results: meetingSearchResults });
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                setError(error);
            });
        }
    }

    useEffect(() => {
        searchMeeting(recentQueryTerm);
    }, []);

    return (
        <>
            {
                show &&
                <Segment>
                    <SearchMeeting isLoading={isLoading} onSearch={searchMeeting} />
                    <SearchResults isLoading={isLoading} search={searchResults} error={error} onRefreshClick={searchMeeting} />
                </Segment>
            }
        </>
    )
}


const SearchMeeting = props => {
    const { isLoading, onSearch } = props;
    const [searchQuery, setSearchQuery] = useState();

    const doSearch = () => {
        onSearch(searchQuery);
    }

    return (
        <Segment textAlign='center' basic>
            <Header as="h2">הצג מפגש על פי ID</Header>
            <Input type='search' placeholder='חפש מזהה מפגש' iconPosition='left' onChange={(e, data) => setSearchQuery(data.value)} action>
                <input />
                <Icon name="search" />
                <Button onClick={doSearch} loading={isLoading} disabled={isLoading || !searchQuery} >
                    חפש
                        <Icon name='chevron right' />
                </Button>
            </Input>
        </Segment>
    );
}

const SearchResults = props => {
    const { isLoading, search, error, onRefreshClick } = props;
    const { query, results } = search;
    let placeholders = [];
    for (var i = 0; i < 10; i++) {
        placeholders.push(<SearchPlaceholder key={i} />);
    }
    return (
        <>
            <Header as="h4">
                {!isLoading && !error && (query === recentQueryTerm ? 'מפגשים שנוצרו לאחרונה:' : `תוצאות חיפוש עבור "${query}":`)}
                <span style={{ float: 'left', cursor: 'pointer' }} onClick={() => onRefreshClick(query)}>
                    <Label color="green"><Icon name="refresh" size="small" />רענן תוצאות</Label>
                </span>
            </Header>
            {isLoading && placeholders}
            {!isLoading && !error && results && results.exist && <ResultsTable results={results.meetings} />}
            {results && !results.exist && <NoResults query={query} />}
            {error && <ErrorMessage message={error.message} />}
        </>
    );
}

const ResultsTable = props => {
    const { results } = props;
    const rows = results.map(meeting => {
        return <ResultRow key={meeting.id} meeting={meeting} />
    });
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>כותרת</Table.HeaderCell>
                    <Table.HeaderCell>מנחה/מרצה</Table.HeaderCell>
                    <Table.HeaderCell>מתחיל</Table.HeaderCell>
                    <Table.HeaderCell>גישה</Table.HeaderCell>
                    <Table.HeaderCell>נוצר</Table.HeaderCell>
                    <Table.HeaderCell>פעולות</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows}
            </Table.Body>
        </Table>
    );
}

const ResultRow = props => {
    const { meeting } = props;
    const { title, lecturer, startsAt, access, metadata } = meeting.data;
    const shareableLink = window.location.origin + process.env.REACT_APP_PATH_ZOOM_MEETING + '/' + meeting.id;
    const clickableStyle = { cursor: "pointer" };
    return (
        <Table.Row>
            <Table.Cell>{title}</Table.Cell>
            <Table.Cell>{lecturer}</Table.Cell>
            <Table.Cell>{dateFormat(startsAt.toDate())}</Table.Cell>
            <Table.Cell positive={!access.isRestricted} warning={access.isRestricted}>
                {access.isRestricted ? <><Icon name='lock' /> מוזמנים בלבד</> : <><Icon name='globe' /> פתוח לקהל הרחב</>}
            </Table.Cell>
            <Table.Cell>{dateFormat(metadata.createdAt.toDate())}</Table.Cell>
            <Table.Cell textAlign="center">
                <CopyToClipboard text={shareableLink} style={clickableStyle}>
                    <Icon name="chain" color="blue" />
                </CopyToClipboard>
                {process.env.REACT_APP_RELEASE === "dev" &&
                    <>
                        <hr />
                        <Icon name="edit" style={clickableStyle} color="green" />
                        <hr />
                        <Icon name="trash" style={clickableStyle} color="red" />
                    </>
                }
            </Table.Cell>
        </Table.Row>
    );
}

const NoResults = props => {
    const { query } = props;
    return (
        <Message size='large'>
            <Message.Header>{query === recentQueryTerm ? "אין עדיין פגישות שנוצרו" : `לא נמצאו תוצאות עבור "${query}"`}</Message.Header>
            {query === recentQueryTerm ?
                "עבור לכרטיסיית 'צור מפגש חדש' כדי ליצור את המפגש הראשון" :
                "שים לב כי המערכת תומעת בחיפוש ID מדוייק של מפגש בלבד"
            }
        </Message>
    );
}

const SearchPlaceholder = () => {
    return (
        <Placeholder fluid>
            <Placeholder.Header>
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Header>
        </Placeholder>
    );
}

export default SearchAndEdit
