import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Segment, Icon } from 'semantic-ui-react';
import "./Error404.css";

export default function Error404() {
    return (
        <Segment textAlign="center" color="red" >
            {/*<p className="title-404">Error 404</p>*/}
            <Header as='h1' icon>
                <Icon name='x' />
                <div style={{ color: 'red', fontSize: 50 }}>שגיאה 404</div>
                <br />
                <Header.Subheader>
                    <h2>זה אומר שהדף שחיפשת לא קיים. <Link to={process.env.REACT_APP_PATH_ISRAGEN_REDIRECTOR} style={{ color: 'green' }}><u>לאתר עיל"ם</u></Link></h2>
                </Header.Subheader>
            </Header>
            <h4 style={{ color: 'gray' }}>אם הגעת לכאן דרך קישור שנשלח אליך, יש לוודא כי הקישור הועתק במלואו.</h4>
        </Segment>
    )
}
