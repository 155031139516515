import React from 'react';
import ReactDOM from 'react-dom';

//import 'semantic-ui-css/semantic.min.css'
import "react-datepicker/dist/react-datepicker.css";
//import './index.css';
import App from './components/App/App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
