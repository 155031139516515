const helpArticles = [
    {
        title: "אימות כתובת מייל",
        content: <p style={{ fontSize: 18 }}>
            כדי שנהיה בטוחים שרק מוזמנים מקבלים גישה למפגשי עיל"ם, יצרנו מערכת לאימות כתובת המייל של המבקשים להצטרף למפגש.
            <br />
            כדי לאמת את כתובתך יש להזין אותה בתיבת הטקסט כאשר תתבקש לכך. לאחר מכן, תשלח אליך הודעת מייל עם קישור. יש ללחוץ על הקישור ולסמן את תיבת "אני לא רובוט". לאחר מכן, תועבר לפגישה.
            <br />
            לרוב תתבקש לאמת את כתובת המייל שלך באופן חד פעמי ואנו נזכור אותה לכל המפגשים הבאים. כתובת המייל לא תשמר אם הינך מתחבר ממכשיר שונה או שעבר זמן רב מאז שהתחברת למפגש האחרון.
            <br />
            אם יש לך שאלות בנוגע להצטרפות לפגישה לאחר האימות, אנא קרא את המאמר הבא.
        </p>,
        hashLink: "email-verification"
    },
    {
        title: 'כניסה למפגשי Zoom',
        content: <p style={{ fontSize: 18 }}>
            לאחר אימות כתובת המייל, תגיע לדף המציג את פרטי המפגש.
            <br />
            יש ללחוץ על הכפתור הירוק ("הצטרף!"). לאחר מכן יפתח חלון בדפדפן השואל אם ברצונך להמשיך לתוכנת ה"זום". יש ללחוץ "המשך".
            <br />
            לאחר מכן תפתח תוכנת הזום ותועבר לחדר ההמתנה של המפגש.
        </p>,
        hashLink: "enter-a-zoom-meeting"
    }
];

export { helpArticles };
export default helpArticles;