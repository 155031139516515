import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from "../../components/Firebase/firebase";

const AuthContext = createContext();

const adminDataRef = db.collection(process.env.REACT_APP_ADMIN_DATA_COL);
let _adminData = {
    loading: true,
    error: null,
    data: null
};

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [adminData, setAdminData] = useState(_adminData)

    const sendSignInEmail = (email, urlParams) => {
        let actionCodeSettings = {
            url: window.location.origin + process.env.REACT_APP_EMAIL_AUTH_LANDING + urlParams, //URL must be in the authorized domains list in the Firebase Console.
            handleCodeInApp: true //This must be true.
        };
        return auth.sendSignInLinkToEmail(email, actionCodeSettings);
    }

    const getAdminData = uid => {
        return adminDataRef.doc(uid).get();
    }

    const updateAdminDataState = (isLoading, error, data) => {
        _adminData = {
            loading: isLoading,
            error: error,
            data: data
        };
        setAdminData(_adminData);
    }

    const passwordlessSignIn = link => {
        if (auth.isSignInWithEmailLink(link)) {
            var email = window.localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_EMAIL_FOR_SIGNIN);
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            return auth.signInWithEmailLink(email, link);
        }
        else {
            return Promise.reject("The login link is invalid.");
        }
    }

    const logOut = () => {
        return auth.signOut().then(() => {
            return Promise.resolve();
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
            if (user) {
                updateAdminDataState(true, null, null);
                getAdminData(user.uid).then(admin => {
                    if (admin.exists) updateAdminDataState(false, null, admin.data());
                    else updateAdminDataState(false, null, null);
                }).catch(error => {
                    updateAdminDataState(false, error, null);
                });
            }
            else updateAdminDataState(false, null, null);
        });
        return unsubscribe;
    }, [])

    const value = {
        currentUser,
        adminData,
        sendSignInEmail,
        passwordlessSignIn,
        logOut
    }

    return (
        <AuthContext.Provider value={value}>
            {!isLoading && children /*I allow the conditional only because the check should be super quick*/}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}