import React, { useState, useEffect } from 'react';
import ZoomMeetingDetails from './MeetingDetails';
import { useAuth } from "../../contexts/Firebase/AuthContext";
import { viewMeeting, joinMeeting } from "../Firebase/meeting";
import ErrorMessage from '../Error/ErrorMessage';
import { Loader, Header } from 'semantic-ui-react';

function ZoomMeeting(props) {
    const { match } = props;
    const [meeting, setMeeting] = useState();
    const [isLoadingMeeting, setIsLoadingMeeting] = useState(true);
    const [error, setError] = useState();
    const [isAllowJoin, setIsAllowJoin] = useState(false)
    const meetingId = match.params.meetingId;
    const { currentUser } = useAuth();

    const constructIntentLink = (zoomMeetingId, pwdHash) => {
        return `zoommtg://zoom.us/join?confno=${zoomMeetingId}&pwd=${pwdHash}&zc=0`;
    }

    const doJoinMeeting = () => {
        return joinMeeting(meetingId).then(meetingCreds => {
            const { meetingZoomId, passwordHash } = meetingCreds;
            window.location.replace(constructIntentLink(meetingZoomId, passwordHash));
            return Promise.resolve(null);
        });
    }

    useEffect(() => {
        viewMeeting(meetingId).then(meetingData => {
            setMeeting({ id: meetingId, data: meetingData });
            setIsAllowJoin(true); //Return if meeting startsAt < now
            setIsLoadingMeeting(false);
            setError(null);
        }).catch(error => {
            setError(error);
            setIsLoadingMeeting(false);
        });
    }, [currentUser, meetingId, props.history]);


    return (
        <div>
            <Header as='h1' textAlign="center" dividing>הצטרף למפגש</Header>
            {!!isLoadingMeeting ? <Loader active inline='centered' content="טוען מפגש..." /> :
                !error ? <ZoomMeetingDetails meeting={meeting} allowJoin={isAllowJoin} launchMeeting={doJoinMeeting} /> :
                    <ErrorMessage message={error.message} />
            }
        </div>
    )
}

export default ZoomMeeting;