import React from 'react';
import { Segment, Header, Image } from 'semantic-ui-react';

import isragen_logo from "../../media/igs-circle-logo.png"
import igsHeaderStrip from "../../media/igs-header-strip.jpg"

function About() {
    return (
        <Segment dir="rtl" textAlign="center">
            <center><Image src={igsHeaderStrip} size='big' /></center>
            <Header as="h1">לאן הגעתי ומה זה האתר הזה?</Header>
            <Header size='medium'>
                זוהי מערכת של העמותה הישראלית לחקר שורשי משפחה (עיל"ם) שתכניס אותך למפגש שאליו נרשמת.
                    <br />כדי להכנס למפגש שאליו נרשמת, עליך להזין את כתובת המייל שלך (כאשר תתבקש/י) ולעקוב אחר ההוראות אשר יופיעו על המסך.
                    <br />כאשר המערכת תזהה אותך, תועבר/י למפגש המבוקש.
            </Header>
        </Segment>
    )
}

export default About;