import React from 'react';
import { useAuth } from "../../contexts/Firebase/AuthContext";
import { Menu, Image, Header, Icon, Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import isragen_logo from "../../media/igs-circle-logo.png"


const UserDisplay = props => {
    const { currentUser, logOut } = props;

    const logoutOnClick = () => {
        logOut().then(() => {
            //logged out
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            {currentUser ?
                <Header as="h5">
                    <Icon name='user circle' color="green" />
                    <Header.Content>
                        מחובר בתור
                        <Header.Subheader>{currentUser.email} <u style={{ color: "#1E90FF", cursor: "pointer" }} onClick={logoutOnClick}>התנתק</u></Header.Subheader>
                    </Header.Content>
                </Header> :
                <Header as="h5" style={{ cursor: "pointer" }}>
                    <Icon name='user' size="tiny" color="green" />
                    <Header.Content>
                        חבר/י את כתובת המייל שלך למפגשים עתידיים
                    </Header.Content>
                </Header>
            }
        </>
    );
}


const NavBar = props => {
    const { currentUser, logOut, adminData } = useAuth();
    const isAdminEnabled = adminData.data && adminData.data.isAdminEnabled;
    return (
        <>
            <Menu stackable style={{ borderBottom: '2px solid #43a960' }}>
                <Menu.Item><Image src={isragen_logo} size='mini' /> {process.env.REACT_APP_RELEASE === "dev" && <Label color="red">BETA</Label>}</Menu.Item>
                <Menu.Item
                    as={Link}
                    to={process.env.REACT_APP_PATH_ISRAGEN_REDIRECTOR}
                    name='gotoIGS'
                ><h4>חזור לאתר עיל"ם</h4></Menu.Item>
                <Menu.Item
                    as={Link}
                    to={process.env.REACT_APP_PATH_ABOUT}
                    name='about'
                ><h4>מה זה?</h4></Menu.Item>
                <Menu.Item
                    as={Link}
                    to={process.env.REACT_APP_PATH_HELP}
                    name='help'
                ><h4>שאלות נפוצות</h4></Menu.Item>
                {isAdminEnabled && <Menu.Item
                    as={Link}
                    to={process.env.REACT_APP_PATH_DASHBOARD}
                    name='adminDashboard'
                    style={{ color: 'green' }}
                ><h4>לוח בקרה למנהלים</h4></Menu.Item>}
                <Menu.Item
                    as={!currentUser ? Link : null}
                    to={process.env.REACT_APP_PATH_LOGIN}
                    position='right'
                    name='user'
                    style={{ cursor: "context-menu" }}
                >
                    <UserDisplay currentUser={currentUser} logOut={logOut} />
                </Menu.Item>
            </Menu>
        </>
    )
}

export default NavBar;