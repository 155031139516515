import React from 'react';
import { Message, Icon } from 'semantic-ui-react';


const ErrorMessage = props => {
    const { message } = props;
    return (
        <Message icon error>
            <Icon name='exclamation circle' />
            <Message.Content>
                <Message.Header>אופס! אירעה שגיאה</Message.Header>
                {message}
            </Message.Content>
        </Message>
    )
}

export default ErrorMessage
