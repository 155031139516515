import React, { useState } from 'react';
import { Grid, Menu, Icon, Header, Label } from 'semantic-ui-react';
import CreateMeeting from './CreateMeeting';
import SearchAndEdit from './SearchAndEdit';

const tabNames = { searchAndEdit: 'searchAndEdit', newMeeting: 'newMeeting', accountsControl: 'accounts' };

const SideMenu = props => {
    const { onTabClick, activeItem } = props;
    return (
        <Grid.Column width={4}>
            <Menu fluid vertical pointing secondary /* *or* tabular*/ >
                <Menu.Item
                    name={tabNames.searchAndEdit}
                    active={activeItem === tabNames.searchAndEdit}
                    onClick={onTabClick}
                ><Icon name='unordered list' /> מפגשים קיימים</Menu.Item> {/*Recents and search to view or edit*/}
                <Menu.Item
                    name={tabNames.newMeeting}
                    active={activeItem === tabNames.newMeeting}
                    onClick={onTabClick}
                ><Icon name='asterisk' /> צור מפגש חדש</Menu.Item>
                <Menu.Item
                    name={tabNames.accountsControl}
                    active={activeItem === tabNames.accountsControl}
                    onClick={onTabClick}
                ><Icon name='user' /> בקרת חשבונות</Menu.Item>
            </Menu>
        </Grid.Column>
    );
}


const TabSwitcher = props => {
    const { tab } = props;

    //Unmounts the component so causes repeated server calls every time mounted again
    /*let componentToRender = null;
    switch (tab) {
        case tabNames.searchAndEdit:
            componentToRender = <><SearchAndEdit /></>;
            break;
        case tabNames.newMeeting:
            componentToRender = <><h1>New meeting</h1><SearchAndEdit hide /></>;
            break;
        case tabNames.accountsControl:
            componentToRender = <><h1>Accounts Control</h1><SearchAndEdit hide /></>;
            break;
        default:
            componentToRender = <h2 style={{ color: 'red' }}>An error occurred. Please refresh the page.</h2>;
            break;
    }
    return componentToRender;*/

    return (
        <>
            <SearchAndEdit show={tab === tabNames.searchAndEdit} />
            <CreateMeeting show={tab === tabNames.newMeeting} />
            {tab === tabNames.accountsControl && <><h1>בקרת חשבונות</h1><Label color="red"><h4>בקרוב...</h4></Label></>}
        </>
    );
}


const Dashboard = () => {
    const [activeTab, setActiveTab] = useState(tabNames.searchAndEdit);
    const onTabClick = (e, { name }) => setActiveTab(name);
    return (
        <>
            <Header as='h1' textAlign="center" dividing><Icon name="dashboard" color="green" /> לוח בקרה למנהלים</Header>
            <Grid>
                <SideMenu onTabClick={onTabClick} activeItem={activeTab} />
                <Grid.Column stretched width={12}>
                    <TabSwitcher tab={activeTab} />
                </Grid.Column>
            </Grid>
        </>
    );
}

export default Dashboard;
